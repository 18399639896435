var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("layout", [
    _c(
      "div",
      {
        staticClass: "section camera",
        attrs: { slot: "content" },
        slot: "content",
      },
      [
        _c(
          "GlobalDialog",
          {
            attrs: { dialogVisible: _vm.allowCameraVisible, title: "提示" },
            on: {
              "dialog-cancel": function ($event) {
                _vm.allowCameraVisible = false
              },
            },
          },
          [
            _c("div", { staticClass: "detect-result" }, [
              _c("i", { staticClass: "iconfont iconshexiangtou2" }),
              _c("div", { staticClass: "right" }, [
                _c("span", { staticClass: "title" }, [
                  _vm._v("请允许使用摄像头"),
                ]),
                _c("span", { staticClass: "content" }, [
                  _vm._v("点击浏览器右上角，开启摄像头"),
                ]),
                _c("span", { staticClass: "content2" }, [
                  _vm._v("设备开启后，刷新页面方可生效"),
                ]),
              ]),
            ]),
          ]
        ),
        _c(
          "GlobalDialog",
          {
            attrs: { dialogVisible: _vm.imageCanNotUse, title: "提示" },
            on: {
              "dialog-cancel": function ($event) {
                _vm.imageCanNotUse = false
              },
            },
          },
          [
            _c("div", { staticClass: "detect-result" }, [
              _c("i", { staticClass: "iconfont iconcuowu1" }),
              _c("div", { staticClass: "right" }, [
                _c("span", { staticClass: "title2" }, [
                  _vm._v("您的自拍照不合规！"),
                ]),
                _c("span", { staticClass: "content" }, [
                  _vm._v(" 请正对电脑摄像头，保持光线明亮， "),
                ]),
                _c("span", { staticClass: "content" }, [
                  _vm._v("靠近摄像头拍照。"),
                ]),
              ]),
            ]),
          ]
        ),
        _c(
          "GlobalDialog",
          {
            attrs: {
              dialogVisible: _vm.canNotModifyImage,
              showSecond: true,
              btn_title: "重新拍照",
              btn_title2: "提交",
              title: "提示",
            },
            on: {
              "dialog-cancel": () => {
                _vm.canNotModifyImage = false
                _vm.reSnap()
              },
              "dialog-ok": () => {
                _vm.canNotModifyImage = false
                _vm.uploadAvatar()
              },
            },
          },
          [
            _c("div", { staticClass: "detect-result" }, [
              _c("i", { staticClass: "iconfont icontishi" }),
              _c("div", { staticClass: "right" }, [
                _c("span", { staticClass: "title2" }, [
                  _vm._v("自拍照提交后不能再修改！"),
                ]),
                _c("span", { staticClass: "content" }, [
                  _vm._v(" 该照片仅用于考试中人脸比对。 "),
                ]),
                _c("span", { staticClass: "content" }, [
                  _vm._v("你确认提交吗？"),
                ]),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "title-container" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("摄像头拍照")]),
            _vm.isVideo
              ? _c(
                  "router-link",
                  {
                    staticClass: "check",
                    attrs: {
                      tag: "a",
                      target: "_blank",
                      to: { name: "DeviceDetection" },
                    },
                  },
                  [_vm._v(" 无法拍照？设备检测 ")]
                )
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "tips" }, [
          _vm._v(
            " * 请先自拍头像，考试全程摄像头会随机抓拍，您的所有个人信息将会严格保密 "
          ),
        ]),
        _c("div", { staticClass: "cam-container" }, [
          _vm.isDenied
            ? _c("div", { staticClass: "user" }, [
                _c("i", { staticClass: "iconfont iconuser" }),
              ])
            : _c("div", [
                _c("video", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.avatar && _vm.isVideo,
                      expression: "!avatar && isVideo",
                    },
                  ],
                  attrs: { id: "video", muted: "muted", width: "500" },
                  domProps: { muted: true },
                }),
                _vm.avatar
                  ? _c("img", {
                      staticClass: "camera2",
                      attrs: {
                        src: `${_vm.file_host}${_vm.avatar}?${Math.random()}`,
                        alt: "",
                        width: "500",
                        height: "375",
                      },
                    })
                  : _vm._e(),
                _c("canvas", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.avatar && !_vm.isVideo,
                      expression: "!avatar && !isVideo",
                    },
                  ],
                  staticClass: "camera2",
                  attrs: { id: "camera", width: "500", height: "375" },
                }),
              ]),
          !_vm.$store.state.userInfo.validImage
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.avatar || !_vm.isVideo,
                      expression: "avatar || !isVideo",
                    },
                  ],
                  staticClass: "re-snap",
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "re-snap-btn",
                      on: {
                        click: function ($event) {
                          return _vm.reSnap()
                        },
                      },
                    },
                    [_vm._v("重新拍照")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c(
          "div",
          { staticClass: "operate" },
          [
            _c("span", { staticClass: "prev", on: { click: _vm.prev } }, [
              _vm._v("上一步"),
            ]),
            _c(
              "el-button",
              {
                staticClass: "snap",
                style: _vm.isDenied ? "background: #ccc" : "",
                attrs: { disabled: _vm.isDenied },
                on: {
                  click: function ($event) {
                    !_vm.isVideo || _vm.avatar
                      ? _vm.$store.state.userInfo.validImage
                        ? _vm.next()
                        : (_vm.canNotModifyImage = true)
                      : _vm.snap()
                  },
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      !_vm.isVideo || _vm.avatar ? "已拍照，下一步" : "拍照"
                    ) +
                    " "
                ),
              ]
            ),
            _vm.$store.state.examInfo.isSkipCapture !== false
              ? _c("span", { staticClass: "next" }, [
                  _vm.isVideo &&
                  _vm.$store.state.examInfo.cameraMonitorType !== 1
                    ? _c("span", { on: { click: _vm.next } }, [
                        _vm._v("没有摄像头，下一步"),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }