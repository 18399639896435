<!--
 * @Author: Libra
 * @Date: 2024-05-22 16:12:04
 * @LastEditors: Libra
 * @Description:
-->
<template>
  <layout>
    <div slot="content" class="section camera">
      <GlobalDialog
        :dialogVisible="allowCameraVisible"
        @dialog-cancel="allowCameraVisible = false"
        title="提示"
      >
        <div class="detect-result">
          <i class="iconfont iconshexiangtou2"></i>
          <div class="right">
            <span class="title">请允许使用摄像头</span>
            <span class="content">点击浏览器右上角，开启摄像头</span>
            <span class="content2">设备开启后，刷新页面方可生效</span>
          </div>
        </div>
      </GlobalDialog>
      <GlobalDialog
        :dialogVisible="imageCanNotUse"
        @dialog-cancel="imageCanNotUse = false"
        title="提示"
      >
        <div class="detect-result">
          <i class="iconfont iconcuowu1"></i>
          <div class="right">
            <span class="title2">您的自拍照不合规！</span>
            <span class="content">
            请正对电脑摄像头，保持光线明亮，
            </span>
            <span class="content">靠近摄像头拍照。</span>
          </div>
        </div>
      </GlobalDialog>
      <GlobalDialog
        :dialogVisible="canNotModifyImage"
        :showSecond="true"
        btn_title="重新拍照"
        btn_title2="提交"
        @dialog-cancel="() => {
          canNotModifyImage = false
          reSnap()
        }"
        @dialog-ok="() => {
          canNotModifyImage = false
          uploadAvatar()
        }"
        title="提示"
      >
        <div class="detect-result">
          <i class="iconfont icontishi"></i>
          <div class="right">
            <span class="title2">自拍照提交后不能再修改！</span>
            <span class="content">
            该照片仅用于考试中人脸比对。
            </span>
            <span class="content">你确认提交吗？</span>
          </div>
        </div>
      </GlobalDialog>
      <div class="title-container">
        <div class="title">摄像头拍照</div>
        <router-link
          tag="a"
          class="check"
          target="_blank"
          v-if="isVideo"
          :to="{ name: 'DeviceDetection' }"
        >
          无法拍照？设备检测
        </router-link>
      </div>
      <div class="tips">
        * 请先自拍头像，考试全程摄像头会随机抓拍，您的所有个人信息将会严格保密
      </div>
      <div class="cam-container">
        <div class="user" v-if="isDenied">
          <i class="iconfont iconuser"></i>
        </div>
        <div v-else>
          <video
            id="video"
            muted="muted"
            width="500"
            v-show="!avatar && isVideo"
          ></video>
          <img
            v-if="avatar"
            class="camera2"
            :src="`${file_host}${avatar}?${Math.random()}`"
            alt=""
            width="500"
            height="375"
          />
          <canvas
            v-show="!avatar && !isVideo"
            id="camera"
            class="camera2"
            width="500"
            height="375"
          >
          </canvas>
        </div>
        <div class="re-snap" v-show="avatar || !isVideo" v-if="!$store.state.userInfo.validImage">
          <el-button class="re-snap-btn" @click="reSnap()">重新拍照</el-button>
        </div>
      </div>
      <div class="operate">
        <span class="prev" @click="prev">上一步</span>
        <el-button
          :disabled="isDenied"
          :style="isDenied ? 'background: #ccc' : ''"
          class="snap"
          @click="!isVideo || avatar ? $store.state.userInfo.validImage ? next() : canNotModifyImage = true : snap()"
        >
          {{ !isVideo || avatar ? '已拍照，下一步' : '拍照' }}
        </el-button>
        <span class="next" v-if="$store.state.examInfo.isSkipCapture !== false">
          <span
            v-if="isVideo && $store.state.examInfo.cameraMonitorType !== 1"
            @click="next"
            >没有摄像头，下一步</span
          >
        </span>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from '@/views/confirm/layout'
import GlobalDialog from '@/components/GlobalDialog'
import Api from '@/api/api'
import { mapMutations, mapActions } from 'vuex'
import { compressImg, dataURLtoFile } from '@/utils/common.js'
import { getItem } from '@/utils/storage'
import { file_host } from '@/api/config'

export default {
  name: 'ConfirmCamera',
  components: {
    Layout,
    GlobalDialog
  },
  data() {
    return {
      context: null,
      video: null,
      canvas: null,
      isVideo: true,
      mediaStreamTrack: null,
      isDenied: false,
      allowCameraVisible: false,
      canNotModifyImage: false,
      imageCanNotUse: false,
      snapEnabled: false,
      avatar: null,
      isFace: false,
      file_host: file_host,
      path: ''
    }
  },
  created() {
    this.isFace = Boolean(this.$route.query.isFace) || false
  },
  mounted() {
    this.info()
    this.initCamera()
  },
  beforeDestroy() {
    this.stopCamera()
  },
  methods: {
    ...mapActions([`updateUserInfo`]),
    ...mapMutations([`setUserInfo`]),
    // 初始化摄像机
    initCamera() {
      const loadingInstance = this.$loading({
        background: 'transparent',
        fullscreen: false,
        target: document.querySelector('.cam-container'),
        text: '数据加载中，请稍后...'
      })
      const canvas = document.getElementById('camera')
      this.canvas = canvas
      const video = document.getElementById('video')
      this.video = video
      this.context = canvas.getContext('2d')
      const videoObj = {
        video: true
      }
      const promise = navigator.mediaDevices.getUserMedia(videoObj)
      promise
        .then(MediaStream => {
          this.mediaStreamTrack = MediaStream
          video.srcObject = MediaStream
          video.play()
          loadingInstance.close()
          this.snapEnabled = true
        })
        .catch(PermissionDeniedError => {
          loadingInstance.close()
          this.isDenied = true
          this.allowCameraVisible = true
          console.log(PermissionDeniedError)
          console.log('请允许打开摄像头')
        })
    },
    // 拍照
    async snap() {
      if (!this.isVideo) {
        this.next()
      }
      if (!this.avatar && !this.isDenied) {
        this.context.drawImage(this.video, 0, 0, 500, 375)
        this.isVideo = false
        this.stopCamera()
      } else {
        if (!this.isDenied) {
          this.next()
        } else {
          this.$message.error('摄像头打开失败，无法拍照')
        }
      }
    },
    // 获取用户信息
    info: function() {
      const resData = this.$store.state.userInfo
      if (this.isFace && !resData.validImage) {
        this.avatar = null
      } else {
        this.avatar = resData.avatar
      }
    },
    // 上传头像
    async uploadAvatar() {
      const candidateUuid = this.$store.state.userInfo.candidateUuid
      const r = await this.getToken()
      this.path = r.data.avatarPath
      compressImg(
        dataURLtoFile(this.canvas.toDataURL(), `${this.path}/${candidateUuid}.jpg`),
        this.canvas,
        `${this.path}/${candidateUuid}.jpg`
      ).then(async data => {
        await this.ossUpload(data, data)
        const res2 = await Api.avatarUpdate({
          path: `${this.path}/${candidateUuid}.jpg`
        })
        if (res2.code === 0) {
          if (!res2.data) {
            this.imageCanNotUse = true
          } else {
            this.updateValidImage()
            this.next()
          }
        } else {
          this.$message.error('上传头像失败')
        }
        const res = JSON.parse(getItem(`userInfo`) || '{}')
        const random = Math.random()
        res.avatar = `${this.path}/${candidateUuid}.jpg?${random}`
        this.setUserInfo(res)
        this.updateUserInfo(res)
      })
    },
    updateValidImage: async function() {
      const candidateUuid = this.$store.state.userInfo.candidateUuid
      const res = JSON.parse(getItem(`userInfo`) || '{}')
      const random = Math.random()
      res.validImage = `${this.path}/${candidateUuid}.jpg?${random}`
      this.setUserInfo(res)
      this.updateUserInfo(res)
    },
    // 更新切片信息
    updateSlice: async function(data) {
      await Api.updateSlice(data)
    },
    // 获取 oss Token
    getToken: async function() {
      const res = await Api.ossToken()
      this.oss = res.data
      return res
    },
    // 上传阿里云
    ossUpload: async function(blob, file) {
      const data = {
        filename: file.name,
        blob: blob,
        ossInfo: this.oss
      }
      await Api.ossUploadFile(data)
    },
    // 停止摄像
    stopCamera() {
      if (this.mediaStreamTrack) {
        this.mediaStreamTrack.getTracks()[0].stop()
      }
    },
    // 上一步
    prev() {
      this.$router.push('/basic')
    },
    // 下一步
    async next() {
      const mobileMonitor =
        this.$store.state.examInfo.mobileMonitorType === 0 &&
        this.$store.state.examInfo.mobileVideoType === 0
      mobileMonitor
        ? this.$router.push('/notice')
        : this.$router.push('/mobile')
    },
    // 重新拍照
    reSnap() {
      this.avatar = ''
      this.isVideo = true
      this.initCamera()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/theme/variables.scss';
@import './index.scss';
</style>
